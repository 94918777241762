import * as React from "react"

import PageLayout from '../components/PageLayout';

const CV_SEC_CODE = '2C2dw';

const CVPage = ({ serverData }) => {
  return (
    <PageLayout pageTitle="CV" showHeader showBackgroundImage>
      <div>
        <h1 class="cv-name">Lara Bärtschi</h1>
        <h2 class="subtitle">DevOps Engineer</h2>
        <div class="cv-info-box">
          <p>🏠 Neuchâtel, Switzerland</p>
          <p>🇨🇭 Swiss Nationality</p>
          <p>📧 <a aria-label="Email" href='mailt&#111;&#58;inf%6&#70;&#64;&#108;a&#114;&#97;&#46;ni&#110;%6Aa' target="_blank" rel="noreferrer" title="Email">i&#110;fo&#64;lara&#46;nin&#106;a</a></p>
          <p>🎂 13. November 2000</p>
          { serverData.confidentialData && <p>☎ +{ serverData.confidentialData.phone }</p> }
        </div>
      </div>
    </PageLayout>
  )
}

export function getServerData(context) {
  if (context.query && context.query["s"] === CV_SEC_CODE) {
    return {
      props: {
        confidentialData: {
          phone: "41 79 953 69 98"
        }
      },
      headers: {},
      status: 200
    }
  } else {
    return {
      props: {},
      headers: {},
      status: 200
    }
  }
}

export default CVPage